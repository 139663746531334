<template>
  <div>
    <edp-header-search
      :headerLeftWord="$t('departmentList.headerLeftWord')"
      :searchPlaceHolder="$t('departmentList.searchPlaceHolder')"
      @search="searchFn"
    ></edp-header-search>

    <div class="topTips fac">
      <img src="@/src/assets/member/notice.png" alt="" class="tip" />
      <span class="f14 op5"> {{ $t("member.dataRange") }}</span>
    </div>

    <section
      class="edp-common-content edp-departmentList"
      v-if="pageList && pageList.length > 0"
    >
      <section
        v-for="(item, index) in pageList"
        :key="'departLine' + index"
        class="edp-departmentList__line"
      >
        <div class="edp-departmentList__line__title">{{ item.org }}</div>

        <section class="edp-departmentList__line__content">
          <div
            class="edp-departmentList__item"
            v-for="(item2, index2) in item.children"
            :key="'departItem' + index2"
          >
            <div
              class="edp-departmentList__item__in bf-op"
              @click="linkDetail(item2)"
            >
              <div
                class="edp-departmentList__item__title"
                :style="item2.bgColor ? 'background: ' + item2.bgColor : ''"
              >
                <img src="@/src/assets/department/depart-logo.png" alt="" />
              </div>

              <div class="edp-departmentList__item__remarks">
                <em>{{ item2.departmentEName }}</em>
                <p>{{ item2.org }}</p>
              </div>
            </div>
          </div>
        </section>
      </section>
    </section>

    <div
      class="user-empty-common tac"
      v-if="!subLoading && (!pageList || pageList.length == 0)"
    >
      <img src="@/src/assets/member/user-empty.png" alt="" class="mb16" />
      <p class="f14 tac">{{ $t("departmentList.resultNull") }}</p>
    </div>

    <van-overlay
      :show="subLoading"
      z-index="120"
      :custom-style="{ background: 'rgba(0,0,0,0.2)' }"
    >
      <div class="loading-wrap-common">
        <van-loading size="50px" color="#3E886D" text-color="#3E886D" vertical
          >&nbsp;</van-loading
        >
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  name: "departmentList",
  data() {
    return {
      keyWords: "",
      pageList: [],
      subLoading: true,
    };
  },
  methods: {
    ...mapActions({
      departList: "departmentList/departList",
    }),
    async pageListInit() {
      this.pageList = [];

      let params = {
        keywords: this.keyWords,
        current: 1,
        size: 1000,
      };
      let res = await this.departList(params);

      this.subLoading = false;

      let dataListLs =
          res.data &&
          res.data.data &&
          res.data.data.records &&
          res.data.data.records.length > 0
            ? res.data.data.records
            : [],
        dataListTag = [];

      dataListLs.forEach((val, e) => {
        if (!dataListTag.includes(val.org)) {
          dataListTag.push(val.org);
          this.pageList.push({ org: val.org, children: [] });
        }
      });

      this.pageList.forEach((val, e) => {
        dataListLs.forEach((val2, e2) => {
          if (val2.org === val.org) {
            val2.bgColor = this.utils.randomColor_depart(e);
            val.children.push(val2);
          }
        });
      });

      this.$forceUpdate();
    },
    linkDetail(data) {
      this.$router.push({
        name: "departmentDetail",
        query: { bgColor: data.bgColor, departmentId: data.departmentId },
      });
    },
    searchFn(data) {
      this.keyWords = data.searchName;

      this.pageListInit();
    },
  },
  mounted() {
    this.pageListInit();
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.edp-departmentList {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 toPad(28) toPad(57) toPad(28);
  overflow: hidden;

  .edp-departmentList__line {
    width: 100%;
  }
  .edp-departmentList__line__title {
    width: 100%;
    font-size: toPad(24);
    font-weight: 600;
    padding-top: toPad(30);
    padding-left: toPad(12);
    font-family: JLREmeric;
  }
  .edp-departmentList__line__content {
    width: 100%;
    overflow: hidden;
  }
  .edp-departmentList__item {
    width: 33.3%;
    padding: toPad(25) toPad(12) 0 toPad(12);
    float: left;
  }
  .edp-departmentList__item__in {
    display: flex;
    width: 100%;
    height: toPad(123);
    padding: toPad(15);
    background: #fff;
    border-radius: toPad(10);
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 toPad(4) toPad(13) rgba(230, 236, 244, 0.6);
  }
  .edp-departmentList__item__title {
    position: relative;
    width: toPad(93);
    height: toPad(93);
    border-radius: toPad(5);
    background: #a4aab0;
    color: #fff;
    margin-right: toPad(19);
    flex-shrink: 0;

    p {
      display: block;
      position: relative;
      z-index: 0;
      width: 100%;
      max-height: toPad(63);
      overflow: hidden;

      opacity: 0.22;
      font-size: toPad(18);
      line-height: 1.2;
      font-weight: 900;
      padding: toPad(2) toPad(10) 0 toPad(10);
      word-break: break-all;
      word-wrap: break-word;
    }
    em {
      display: block;
      position: absolute;
      z-index: 1;
      max-width: 100%;
      max-height: toPad(80);
      overflow: hidden;
      left: toPad(10);

      bottom: toPad(6);

      padding-right: toPad(5);
      font-style: normal;
      font-size: toPad(16);
      line-height: 1.2;
      word-break: break-all;
      word-wrap: break-word;
    }

    img {
      display: block;
      position: absolute;
      width: toPad(50);
      height: toPad(50);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .edp-departmentList__item__remarks {
    flex-basis: 100%;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: JLREmeric;

    em {
      display: block;
      width: 100%;
      font-size: toPad(24);
      line-height: toPad(22);
      font-style: normal;
    }
    p {
      display: block;
      width: 100%;
      font-size: toPad(16);
      line-height: toPad(24);
      padding-top: toPad(11);
    }
  }
}
</style>
